<template>
<!-- 资讯讯息 -->
  <div class="news-container">
    <div class="news-main">
      <div class="content">
        <ul class="news-ul">
          <li class="newsitems" v-for="(item, index) in newsList" :key="index">
            <div
              class="title"
              @click="openBlank('NewsDetail', item.article_id)"
            >
              <el-link :underline="false" :title="item.title">{{ item.title }}</el-link>
            </div>
            <div class="maincon flex flex-between">
              <div class="newsimage">
                <el-image :src="item.thumb" fit="cover" class="thumbnail">
                  <div slot="error" class="image-slot">
                    <img src="@/assets/images/default.png" alt="" />
                  </div>
                </el-image>
              </div>
              <div class="newstxt flex">
                <p>{{ item.content }}</p>
                <div class="release-time">
                  <i class="el-icon-time"></i>
                  <span>{{ item.update_time | formatDate }}</span>
                </div>
              </div>
            </div>
          </li>
        </ul>
        <div class="paging">
          <el-pagination
            background
            @current-change="getNewsList"
            :hide-on-single-page="true"
            :current-page="page"
            layout="prev, pager, next"
            :page-count="totalPage">
          </el-pagination>
        </div>
        <el-empty description="暂无数据" v-if="!newsList.length"></el-empty>
      </div>
    </div>
  </div>
</template>

<script>
import { formatDate } from "@/utils/timeStamp.js";
export default {
   // 过滤时间
  filters: {
    formatDate(time) {
      time = time * 1000;
      let date = new Date(time);
      return formatDate(date, "yyyy-MM-dd");
    },
  },
  data() {
    return {
      type: this.$route.query.type ? this.$route.query.type : "newest",
      tabList: [
        {
          name: "最新资讯",
          type: "newest",
        },
        {
          name: "热门资讯",
          type: "hot",
        },
      ],
      page: 1,
      totalPage: 0,
      newsList: [],
      getUrl: "/api/web/news/getLatestNews?page=2"
    };
  },
  created() {
    this.getNewsList();
  },
  methods: {
    // 列表数据
    getNewsList(val) {
      const page=!!val?val:this.page;
      this.$http.get("/api/web/news/getLatestNews?page="+page).then(res => {
        this.newsList = res.data.list;
        this.totalPage = res.data.totalPage; //分页总数
        console.log(res.data.totalPage)
      })
    },
    openBlank(name, id) {
      let routeUrl = this.$router.resolve({
        name,
        query: { id },
      });
      window.open(routeUrl.href, "_blank");
    },
    // 详情页跳转
    gotoLink(id) {
      this.$router.push({
        name: "NewsDetail",
        params: { id },
      });
    },
  }
};
</script>

<style lang='less' scoped>
.news-nav {
  width: 100%;
  .content {
    width: var(--container-width);
    margin: 0 auto;
    .nav-items {
      display: block;
      position: relative;
      height: 80px;
      font-size: 18px;
      line-height: 80px;
      color: #999999;
      margin: 0 40px;
      cursor: pointer;
      text-decoration: none;
      &.active {
        color: #333;
        &:after {
          content: "";
          display: block;
          position: absolute;
          bottom: 0;
          width: 100%;
          height: 2px;
          background-color: #333333;
        }
      }
    }
  }
}
.news-main {
  width: 1070px;
  padding: 0 0 30px;
  background-color: #f5f5f5;
  .content {
    width: 100%;
    margin: 0 auto;
    .news-ul {
      position: relative;
      width: 100%;
      margin: 0;
      padding: 0;
    }
    .news-ul li {
      float: left;
      width: calc(50% - 10px);
      height: 260px;
      background: white;
      border-radius: 10px;
      margin-bottom: 30px;
      list-style: none;
      padding: 30px;
      .title {
        width: 100%;
        font-size: 22px;
        color: #333333;
        text-align: left;
        margin-bottom: 30px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        .el-link {
          display: block;
          width: 100%;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          font-size: 22px;
          color: #333333;
        }
        .el-link:hover {
          color: var(--main-active-color);
        }
      }
      .maincon {
        width: 100%;
        .newsimage {
          width: 225px;
          height: 140px;
          // background: #F13C07;
          border-radius: 10px;
          overflow: hidden;
          .thumbnail {
            display: block;
            width: 100%;
            height: 140px;
          }
        }
        .newstxt {
          width: calc(100% - 245px);
          height: 140px;
          flex-wrap: wrap;
          align-content: space-between;
          p {
            width: 100%;
            max-height: 96px;
            font-size: 15px;
            color: #666666;
            line-height: 24px;
            text-align: left;
            margin: 0;
            padding: 0;
            overflow: hidden;
          }
          .release-time {
            font-size: 14px;
            color: #666666;
            line-height: 28px;
            text-align: left;
            span {
              margin-left: 5px;
            }
          }
        }
      }
    }
    .news-ul li:nth-child(even) {
      margin-left: 20px;
    }
    .news-ul:after {
      content: "";
      display: block;
      clear: both;
    }
    .paging {
      width: 100%;
      margin-top: 20px;

      /deep/.el-pager li {
        font-size: 16px;
        width: 40px;
        height: 40px;
        line-height: 40px;
        font-weight: normal;
        background-color: white;
      }
      /deep/.el-pager li:not(.disabled).active {
        background-color: #d71a18;
        color: #fff;
      }
      /deep/.btn-next,
      /deep/.btn-prev {
        background-color: white;
        font-size: 16px;
        width: 40px;
        height: 40px;
        line-height: 40px;
        font-weight: normal;
      }
    }
  }
}
</style>
